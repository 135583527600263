
import React, { useEffect, useState, useContext, useRef } from 'react'
import { ROUTES } from '../../../../utils/consts'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { LangContext } from '../../../../localization/localizationContext'
import BrokerMap from './BrokerMap'
import BrokerDetails from './BrokerDetails'
import { REACT_APP_GOOGLE_API_KEY } from '../../../../utils/consts'
import useIsInViewport from '../../../../utils/hooks/useIsInViewport'

const getRandomNumberInRange = (min, max) => (
  Math.random() * (max - min + 1) + min
)
// Random location ranging from south-west Saskatchewan to north-west Ontario. This is to ensure
// that the same broker is not always populating the top spot when location is not available
const defaultLocation = {
  lat: getRandomNumberInRange(49, 53),
  lng: getRandomNumberInRange(-107, -89),
}
/**
 * @summary Loads and initializes state for the main map page of Find a Broker
 */
const FindABroker = ({ brokers, brokerCommError, getParamValue }) => {
  const { translate, lang } = useContext(LangContext)
  const [personalCommercialFilter, setPersonalCommercialFilter] = useState(true)
  const [lifeGroupBenefitsFilter, setLifeGroupBenefitsFilter] = useState(true)
  const [viewBrokersLoaded, setViewBrokersLoaded] = useState(false)
  const [mapCenter, setMapCenter] = useState(defaultLocation)
  const [visibleBrokers, setVisibleBrokers] = useState([])
  const [postalCode, setPostalCode] = useState(getParamValue)
  const [zoom, setZoom] = useState(3)
  const [selectedBrokerCode, setSelectedBrokerCode] = useState('')
  const [forceZoomCheck, setForceZoomCheck] = useState(true)
  const atAcceptableZoom = zoom > 3
  const canonicalURL = window.location.origin + `${ROUTES.FIND_A_BROKER}`
  const mapRef = useRef(null)
  const mapInView = useIsInViewport(mapRef)
  //after we select a prediction or a pin on map, the visible brokers will be updated
  //this state indicates if the update is done
  const [brokerUpdated, setBrokerUpdated] = useState(false)


  // Gets the users Geolocation (if available) and fetches the broker data from express
  useEffect(() => {
    let isPostalCodeAvailable = false;

    if(postalCode) {
      setPostalCode(postalCode);
      isPostalCodeAvailable = true;
    } 
    const initializeMap = async () => {
      if (isPostalCodeAvailable) {
        const coordinates = await fetchCoordinatesByPostalCode(postalCode);
        if (coordinates) {
          setMapCenter(coordinates);
          setZoom(15);
          setForceZoomCheck(true);
        } else { 
          getCurrentLocationAndUpdateMap();
        }
      } else {
        getCurrentLocationAndUpdateMap();
      }
    };

    initializeMap();
  }, [])
  const handleSelectedPin = (pinLoc, code) => {
    // Checking if the selected broker is in the list pane, re-centering if not
    if (!visibleBrokers.find((b) => b.code === code)) setMapCenter(pinLoc)
    setSelectedBrokerCode(code)
  }

  const getCurrentLocationAndUpdateMap = () => { 
    window.navigator.geolocation.getCurrentPosition((success) => {
      const currentLocation = { lat: success.coords.latitude, lng: success.coords.longitude };
      setMapCenter(currentLocation);
      setZoom(15);
      setForceZoomCheck(true);
    }, (error) => {
      console.log(`Couldn't get user geolocation data. For a more personalized experience, please allow geolocation use and then refresh the page. Error: ${error.code}`)
    }, { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true });
  }

  const fetchCoordinatesByPostalCode = async (postalCode) => {
    const apiKey = REACT_APP_GOOGLE_API_KEY;
    const requestUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&key=${apiKey}`;
    try {
      const response = await fetch(requestUrl);
      const data = await response.json();

      if (data.status === 'OK' && data.results[0]) {
        const location = data.results[0].geometry.location;
        return { lat: location.lat, lng: location.lng };
      } else {
        return null;
      }
    } catch (error) {
      console.error(`Error fetching coordinates for postal code: ${postalCode}`, error);
      return null;
    }
  };


  /*scroll functions. keep visibleBrokers as dependency because if visibleBrokers
  are changed, the highlighted broker's position will also be changed. this will ensure we alwasy scroll
  to the correct position
  */
  useEffect(() => {
    if (selectedBrokerCode !== "") {
      let selectedBroker = visibleBrokers.find(item => item.code === selectedBrokerCode)
      //move the selected broker to the top
      if (selectedBroker && visibleBrokers[0] !== selectedBroker) {
        let newBrokers = visibleBrokers.filter(item => item !== selectedBroker)
        newBrokers.unshift(selectedBroker)
        setVisibleBrokers(newBrokers)
      } else {
        document.querySelector('.fab-search-results').scrollTo({ top: 0, behavior: 'smooth' })
        //scroll to the map if map is not shown in view
        if (!mapInView) {
          mapRef.current.scrollIntoView()
        }
      }
    }
  }, [visibleBrokers, selectedBrokerCode])

  const handleUpdateCenter = (newCentre) => {
    //this is the beginning of the broker update, set BrokerUpdated to false
    setBrokerUpdated(false)
    setMapCenter(newCentre)
    setZoom(15)
  }

  const handleZoomChanged = (newZoom) => {
    setZoom(newZoom)
  }

  return (
    <div className='columns content'>
      <div className='column is-12'>
        <div className='pt-6'>
          <Helmet>
            <title>{translate('title')}</title>
            {lang.CODE === "fr" &&
              <link rel="canonical" href={canonicalURL} />
            }
          </Helmet>

          <h1 className='has-underline'>{translate('fab.header')}</h1>
          <p>{translate('fab.fabInstructions[0]')} {<Link to={translate('fab.route.brokerDirectory')} hrefLang={lang.CODE}>{translate('fab.fabInstructions[1]')}</Link>}{translate('fab.fabInstructions[2]')}</p>

          <div className="fab-search-grid">
            <BrokerDetails
              postalCode={postalCode}
              brokerData={brokers}
              mapCenter={mapCenter}
              atAcceptableZoom={atAcceptableZoom}
              selectedBrokerCode={selectedBrokerCode}
              viewBrokersLoaded={viewBrokersLoaded}
              visibleBrokers={visibleBrokers}
              personalCommercialFilter={personalCommercialFilter}
              lifeGroupBenefitsFilter={lifeGroupBenefitsFilter}
              setPersonalCommercialFilter={setPersonalCommercialFilter}
              setLifeGroupBenefitsFilter={setLifeGroupBenefitsFilter}
              handleSelectedPin={handleSelectedPin}
              handleUpdateCenter={handleUpdateCenter}
              brokerCommError={brokerCommError}
              setForceZoomCheck={setForceZoomCheck}
              brokerUpdated={brokerUpdated}
            />
            <BrokerMap
              mapRef={mapRef}
              center={mapCenter}
              atAcceptableZoom={atAcceptableZoom}
              markers={brokers}
              zoom={zoom}
              selectedBrokerCode={selectedBrokerCode}
              personalCommercialFilter={personalCommercialFilter}
              lifeGroupBenefitsFilter={lifeGroupBenefitsFilter}
              handleSelectedPin={handleSelectedPin}
              handleUpdateBrokers={setVisibleBrokers}
              handleZoomChanged={handleZoomChanged}
              setMapCenter={setMapCenter}
              setViewBrokersLoaded={setViewBrokersLoaded}
              setZoom={setZoom}
              forceZoomCheck={forceZoomCheck}
              setForceZoomCheck={setForceZoomCheck}
              setBrokerUpdated={setBrokerUpdated}
            />
          </div>
        </div>
      </div>

    </div>

  )
}
export default FindABroker
