import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { LangContext } from '../../../../localization/localizationContext'
import Result from '../../../../components/views/broker/find-a-broker/Result'
import { Link, useParams } from 'react-router-dom'
import { ROUTES, REACT_APP_GOOGLE_API_KEY, FAB_VIEW } from '../../../../utils/consts'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import logo from '../../../../assets/img/ww-marker.png'
import mapStyle from '../find-a-broker/mapStyle.json'
import UnavailableErrorText from '../find-a-broker/UnavailableErrorText'

const libraries = ['places']

const BrokerProfile = ({ brokers, brokerCommError }) => {
  const { translate, lang } = useContext(LangContext)
  const { code } = useParams()
  const broker = brokers.find((e) => e.code === code)
  const canonicalURL = window.location.origin + `${ROUTES.BROKER_PROFILE}/${code}`

  return (
    <>
      <Helmet>
        <title>{translate('fab.directory.profile') + ' | ' + broker.name}</title>
        {lang.CODE === "fr" &&
          <link rel="canonical" href={canonicalURL} />
        }
      </Helmet>
      <div className='columns content'>
        <div className='column is-12'>
          <div className='columns mt-5'>
            <div className='column is-8'>
              <h1 className='has-underline mb-0'>
                <span className="ww-visually-hidden">{translate('fab.links.profileFor')}</span> {broker.name}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className='columns content'>
        <div className='column is-8'>
          <div className='columns'>
            {brokerCommError && (
              <UnavailableErrorText />
            )}
            <div className='column is-one-third'>
              {broker && (
                <Result
                  key={broker.code}
                  broker={broker}
                  highlighted={false}
                  handleUpdateCenter={undefined}
                  viewType={FAB_VIEW.PROFILE}
                />
              )}
              <Link to={translate('fab.route.brokerDirectory')} hrefLang={lang.CODE} className="button is-info mt-4">
                <i className="fas fa-chevron-left mr-2" aria-hidden="true"></i> {translate('fab.directory.backToDirectory')}
              </Link>
            </div>
            <div className='column'>
              {broker && (
                <LoadScript
                  googleMapsApiKey={REACT_APP_GOOGLE_API_KEY}
                  language={lang.CODE}
                  libraries={libraries}
                >
                  <GoogleMap
                    options={{
                      styles: mapStyle,
                    }}
                    mapContainerClassName='fab-search-profile-map'
                    center={broker.position}
                    zoom={16}
                  >
                    <Marker
                      key={broker.code}
                      title={broker.name}
                      position={broker.position}
                      icon={logo}
                    />
                  </GoogleMap>
                </LoadScript>
              )}
            </div>
          </div>
        </div>
        <div className='column is-4'>

        </div>
      </div>
    </>
  )
}

export default BrokerProfile
