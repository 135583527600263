import React, { useContext, useEffect, useState } from 'react'
import { Switch, Route, Redirect, Link, useHistory } from 'react-router-dom'
import { LangContext } from './localization/localizationContext'
import TagManager from 'react-gtm-module'
import SwitchLanguage from './components/language/SwitchLanguage'
import Broker from './components/views/broker/Broker'
import Layout from './components/layout/Layout'
import { REACT_APP_SITE, REACT_APP_GTM_TAG_ID, NODE_ENV, ROUTES} from './utils/consts'
import { Helmet } from 'react-helmet';


TagManager.initialize({
  gtmId: REACT_APP_GTM_TAG_ID,
})

/**
 * @summary Directory of pages, also sets the redirect path for translation.
 */
// eslint-disable-next-line react/display-name
const App = () => {
  const { translate, setRedirect, lang } = useContext(LangContext)
  const [showTempNav, setShowTempNav] = useState(false)
  const history = useHistory()

  /* FIXME: Sets up a mutation observer to watch for the InMoment feedback survey tab to be loaded
   * into the DOM by GTM. Tabs are loaded in as hidden by default, this manually sets the tab to
   * visible. Update may be needed when the proper way to display the tab is found.
   */
  useEffect(() => {
    const surveyTabCallback = (mutationsList, observer) => {
      mutationsList.forEach((mutation) => {
        // On every mutation to the body we check for elements with the inMoment feedback id
        const surveyTabs = Array.from(mutation.addedNodes).filter((node) => (
          node.id && node.id.startsWith('_im_feedbackTab__')
        ))

        if (surveyTabs.length > 0) {
          // English and French tabs are loaded in by GTM
          // Finding the first tab that matches the current language
          const surveyTab = surveyTabs.find((tab) => tab.children[0].textContent === translate('common.feedback'))
          if (surveyTab) {
            // Setting the matching tab to be visible and disconnecting the mutation observer
            surveyTab.style.visibility = 'visible'
            observer.disconnect()
          }
        }
      })
    }

    const body = document.getElementsByTagName('body')[0]
    const mutationConfig = { childList: true }

    // Creating a mutation observer to execute our callback whenever the body is updated
    const observer = new MutationObserver(surveyTabCallback)
    observer.observe(body, mutationConfig)
  }, [translate])

  const path = history.location.pathname
  const languageCode = lang.CODE.toUpperCase()
  const debugHeader = (
    <>
      <button
        type="button"
        onClick={() => setShowTempNav((prev) => !prev)}
        style={{
          position: 'absolute',
          top: 10,
          left: 10,
          zIndex: 9999,
        }}
      >
        {showTempNav ? 'Hide' : 'Show'}
      </button>
      <div style={{
        border: '1px red solid',
        display: showTempNav ? 'flex' : 'none',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: '1000px',
        margin: '0 auto',
      }}
      >
        <p>
          <strong>Current Language: </strong>
          {translate('language') /* Testing translate */}
        </p>
        <p>
          <strong>Browser Language: </strong>
          {navigator.language || navigator.userLanguage}
        </p>
        <Link to={`/secure/claims/CN/${languageCode}/autoclaim`}>AUTO</Link>
        <Link to={`/secure/claims/CN/${languageCode}/homeclaim`}>HOME</Link>
        <Link to={translate('fab.route.broker')}>BROKER</Link>
        <a href="https://wawanesa.com">EXTERNAL LINK</a>
      </div>
    </>
  )

  /* This is for the old layout language switch functionality. The old layout is imported as html so
   * we cannot set links for React Router in the header (such as the language switch button). A
   * component is instead loaded on a route when the language switch button it pressed which toggles
   * the language.
   * useEffect stores the path on the current page so when the url changes to switch languages it
   * redirect to the page the user was last on. When we fully switch to the new template refresh
   * this hook along with the switchLanguage component can be deleted.
   */
  useEffect(() => {
    if (!path.endsWith('switchLanguage')) {
      setRedirect(path)
    }

    //  Removing the html extension from links
    const htmlExtension = '.html'
    if (path.endsWith(htmlExtension)) {
      history.replace(path.slice(0, -htmlExtension.length))
    }
  }, [setRedirect, path, history])

  return (
    <>
      {NODE_ENV === 'development' && debugHeader}
      <Helmet>
        <html lang={languageCode === 'EN' ? 'en-ca' : 'fr-ca'} ></html>
        <link rel="alternate" href={window.location.href} hrefLang="fr-ca" />
        <link rel="alternate" href={window.location.href} hrefLang="en-ca"/>
        <meta name="description" content={translate('fab.meta.broker')} />
      </Helmet>
      <Switch>
        <Route path="/switchLanguage"><SwitchLanguage /></Route>
        <Route exact path="/">
          <Redirect to={ROUTES.FIND_A_BROKER} />
        </Route>
        <Route exact path={[ROUTES.BROKER_PROFILE]}>
          <Redirect to={ROUTES.BROKER_DIRECTORY} />
        </Route>
        <Route exact path={[ROUTES.BROKER_PROFILE_FR]}>
          <Redirect to={ROUTES.BROKER_DIRECTORY_FR} />
        </Route>
        <Route path={[ROUTES.FIND_A_BROKER, ROUTES.FIND_A_BROKER_FR, ROUTES.BROKER_DIRECTORY, ROUTES.BROKER_DIRECTORY_FR, ROUTES.BROKER_PROFILE, ROUTES.BROKER_PROFILE_FR]}>
          <Layout><Broker /></Layout>
        </Route>
        {/* Redirects to 404 page on any unmatched routes */}
        <Route component={() => { window.location.href = `${REACT_APP_SITE}/error/404.html?language_id=${lang.ID}`; return null; }} />
      </Switch>
    </>
  )
}

export default App;
