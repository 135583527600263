import React, { useContext, useState, useEffect} from 'react'
import { Helmet } from 'react-helmet'
import { LangContext } from '../../../../localization/localizationContext'
import { Link, useHistory} from 'react-router-dom'
import { ROUTES, FAB_VIEW } from '../../../../utils/consts'
import Pagination from '../../../../components/common/ui/Pagination'
import Result from '../../../../components/views/broker/find-a-broker/Result'
import { searchBrokers, filterBrokers } from '../../../../utils/mapUtility'
import UnavailableErrorText from '../find-a-broker/UnavailableErrorText'
import SearchFilter from '../../../common/ui/SearchFilter'

const BrokerDirectory = ({ brokers, brokerCommError }) => {
  const { translate, lang } = useContext(LangContext)
  const [directoryBrokers, setDirectoryBrokers] = useState(brokers)
  const [searchTerm, setSearchTerm] = useState('')
  const [personalCommercialFilter, setPersonalCommercialFilter] = useState(true)
  const [lifeGroupBenefitsFilter, setLifeGroupBenefitsFilter] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const history = useHistory()
  const totalPages = Math.ceil(directoryBrokers.length / itemsPerPage)
  const canonicalURL = window.location.origin + `${ROUTES.BROKER_DIRECTORY}`

  // Setting the initial page number based off the url param
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const paramPage = parseInt(urlParams.get('page'), 10)
    if (paramPage >= 1 && paramPage <= totalPages) {
      setCurrentPage(paramPage)
    }
  }, [totalPages, location])

  // Ensuring the current page can never exceed total pages
  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages || 1)
    }
  }, [currentPage, totalPages])

  // Rewriting the url on page change
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set('page', currentPage)
    // eslint-disable-next-line prefer-template
    history.replace(history.location.pathname + '?' + urlParams.toString())
  }, [currentPage, history])

  useEffect(() => {
    if (searchTerm.trim().length >= 3) {
      setDirectoryBrokers(searchBrokers(brokers, searchTerm))
    } else {
      setDirectoryBrokers(brokers)
    }
    // Reset filters on search
    if (!personalCommercialFilter) setPersonalCommercialFilter(true)
    if (!lifeGroupBenefitsFilter) setLifeGroupBenefitsFilter(true)
  }, [searchTerm, brokers])

  useEffect(() => { 
    if (searchTerm.trim().length >= 3) {
      const searchResults = searchBrokers(brokers, searchTerm)
      const filterResults = filterBrokers(searchResults, personalCommercialFilter, lifeGroupBenefitsFilter)
      setDirectoryBrokers(filterResults);
    } else {
      const filterResults = filterBrokers(brokers, personalCommercialFilter, lifeGroupBenefitsFilter)
      setDirectoryBrokers(filterResults);
    }
  }, [personalCommercialFilter, lifeGroupBenefitsFilter])

  const brokerList = directoryBrokers.slice(indexOfFirstItem, indexOfLastItem).map((b) => (
    <Result
      key={b.code}
      broker={b}
      viewType={FAB_VIEW.BROKER_DIRECTORY}
    />
  ))

  let helperText
  if (searchTerm.length < 3) {
    helperText = translate('fab.details.pleaseEnterAtLeast3Letters')
  } 

  const handleCurrentPageAndFocus = (page) => {setCurrentPage(page); document.querySelector('#fab-page-count').focus();}

  return (
    <>
      <Helmet>
        <title>{translate('fab.directory.title')}</title>
        <meta name="description" content={translate('fab.meta.brokerDirectory')}/>
        {lang.CODE === "fr" &&
          <link rel="canonical" href={canonicalURL} />
        }
      </Helmet>
      
      <div className='columns content'>
        <div className='column is-12'>
          <div className='columns mt-5'>
            <div className='column is-8'>
              <h1 className='has-underline mb-0'>{translate('fab.directory.title')}</h1>
            </div>
          </div>
        </div>
      </div>   
      <div className='columns content'>
        <div className='column is-8'>
          <p>
          {translate('fab.description')} <Link to={translate('fab.route.broker')} hrefLang={lang.CODE}>
            {translate('fab.directory.backToMap')} </Link>.
          </p>

          {brokerCommError && (
            <>
              <UnavailableErrorText />
            </>
          )}
           {brokerCommError === false && ( 
               <>
                 <div className='field'>    
                    <label className='label' htmlFor='fab-search'>{translate('fab.directory.filter.instructions')}</label>
                      <div className='control'>
                        <input
                          type="text"
                          size={50}
                          id="fab-search"
                          className="input"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          aria-describedby="fab-search-error"
                        />
                      </div>
                      {searchTerm.length > 0 && <span id="fab-search-error" className="is-size-7">{helperText}</span>}
                 </div>
                 <SearchFilter
                    personalCommercialFilter={personalCommercialFilter}
                    lifeGroupBenefitsFilter={lifeGroupBenefitsFilter}
                    setPersonalCommercialFilter={setPersonalCommercialFilter}
                    setLifeGroupBenefitsFilter={setLifeGroupBenefitsFilter}
                 />
                 <br />
                 <span id="fab-list-count" role="status" aria-live="polite" tabIndex="0">{directoryBrokers.length} {translate('fab.directory.results').toLowerCase()}</span>
                 <span className="mx-2">|</span>
                 <span id="fab-page-count" aria-live="polite" tabIndex="0">{`Page ${currentPage} ${translate('fab.directory.of')} ${totalPages}`}</span>
                 <ul id="fab-list" className='is-unstyled'>
                    {brokerList}
                 </ul>
                 <hr className="has-background-grey-light" aria-hidden="true"></hr>
                 <Pagination
                    current={currentPage}
                    pageSize={itemsPerPage}
                    total={directoryBrokers.length}
                    onChange={handleCurrentPageAndFocus}
                  />
               </>
           )}
        </div>
        <div className='column is-4'>
        </div>
      </div>
    </>
  )
}

export default BrokerDirectory
